import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Layout } from "src/components/layout"
import { BlogPostsList } from "src/components/blog_posts_list"


export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Blog | {data.site.siteMetadata.title}</title>
      </Helmet>
      <BlogPostsList />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`