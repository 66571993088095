import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from "gatsby"


const BlogPostsListContainer = styled.div `

  @media (min-width: 1024px) {
    margin: 2.5rem 0;
    width: 640px;
  }
`

const Title = styled.h1 `
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

const PostUnitContainer = styled.div `
  padding: 1.5rem;
  margin: 0 -1.5rem;
  display: flex;
  flex-direction: row;
  border-top: 2px dotted #f2f2f2;

  &:nth-child(odd) {
    /* margin-top: 0;
    border-top: 0px none; */
    /* background: #f7f7f7; */
  }

  @media (min-width: 1024px) {
    border: 0 none;
  }
`

const PostUnit = styled.div `
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  width: 85%;
`

const PostUnitTitle = styled(Link) `
  font-family: Lato;
  font-weight: 700;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 1.25;
  color: #000;

  &:hover {
    color: #C02907;
  }

`

const PostUnitSubtitle = styled.p `
  margin-top: 0.25rem;
  font-family: Lato;
  font-size: 1.125em;
  margin-bottom: 0;
`

const PostUnitDate = styled.p `
  margin-top: 1rem;
  color: #aaa;
  margin-bottom: 0;
`

const PostCoverImg = styled.div `
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  width: 128px;
  background-size: cover;
  height: 100px;

  
`

const BlogPostsList = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
          filter: { frontmatter: { type: { eq: "post" },  draft: { ne: true } } }, limit: 10 ) {
          totalCount
          edges {
            node {
              id
              frontmatter{
                title
                subtitle
                date(formatString: "MMMM Do, YYYY")
                type
                thumbnail {
                  childImageSharp {
                    fixed(width: 256) {
                      width
                      height
                      src
                      srcSet
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  return (
    <BlogPostsListContainer>
      <Title>
        Blog
      </Title>
      {allMarkdownRemark.edges.map(({ node }) => (  
        <PostUnitContainer>
          <PostUnit>
            <PostUnitTitle to={node.fields.slug}>{node.frontmatter.title}</PostUnitTitle>
            <PostUnitSubtitle>{node.frontmatter.subtitle}</PostUnitSubtitle>
            <PostUnitDate>{node.frontmatter.date}</PostUnitDate>
          </PostUnit>
          <PostCoverImg src={node.frontmatter.thumbnail && node.frontmatter.thumbnail.childImageSharp.fixed.src} />
        </PostUnitContainer>
      ))}
    </BlogPostsListContainer>
  )
}

export { BlogPostsList }